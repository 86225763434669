<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-chip
        :key="dateKey"
        small
        label
        outlined
        class="primaryText--text mx-1 smallText"
        @click="changeDateType()"
      >
        <v-icon x-small left>event</v-icon> Date Type:
        {{ params.dateType }}
      </v-chip>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip
            small
            label
            outlined
            class="primaryText--text mx-1 smallText"
            v-on="on"
          >
            <v-icon x-small left>event</v-icon>
            {{
              quickSelectDates.find((x) => x.value == params.selectedDate)
                ? quickSelectDates.find((x) => x.value == params.selectedDate)
                    .text
                : "Select Date"
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item
            v-for="(date, index) in quickSelectDates"
            :key="index"
            @click="selectDate(date.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ date.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        v-if="params.selectedDate == 'select_dates'"
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="primary"
            class="mx-1"
            outlined
            label
            v-bind="attrs"
            v-on="on"
            small
            ><v-icon x-small left>date_range</v-icon>{{ dateText }}
            <v-icon small right>expand_more</v-icon></v-chip
          >
        </template>
        <v-date-picker
          v-model="params.dates"
          range
          color="primary"
          show-week
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary"> Cancel </v-btn>
          <v-btn text color="primary" @click="setDateFilter"> OK </v-btn>
        </v-date-picker>
      </v-menu>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <span class="mr-1 text" style="font-size: 12px"><b>{{ currentWeek ?? '' }}</b></span>

      <v-chip class="mr-1 searchChipWrap" style="max-width: 400px">
        <!-- <v-btn small icon left color="primary" @click="addNewBooking()"
              ><v-icon small>add_circle_outline</v-icon></v-btn
            > -->
        <v-icon small color="grey" class="ml-1">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px; width: 300px"
          v-model="search"
        ></v-text-field>

        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon small>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon right small @click="getBookings" v-on="on">
              <v-icon small>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Bookings</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      class="ag-grid-theme-builder"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        columnLimits: [],
      }"
      style="width: 100%; height: calc(100vh - 108px - 25px)"
      :rowSelection="'multiple'"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="bookings"
      :getContextMenuItems="getContextMenuItems"
      :getMainMenuItems="getMainMenuItems"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :getRowId="getRowId"
      @columnMoved="columnsChanged"
      @columnPivotChanged="columnsChanged"
      @newColumnsLoaded="columnsChanged"
      @columnValueChanged="columnsChanged"
      @columnPinned="columnsChanged"
      @displayedColumnsChanged="columnsChanged"
      @columnResized="columnsChanged"
      @columnRowGroupChanged="columnsChanged"
      @columnPivotModeChanged="columnsChanged"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>
  <script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import BookingState from "../BookingTables/BookingComponents/BookingState.vue";
import BookingType from "../BookingTables/BookingComponents/BookingType.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import ModeOfTransport from "../GridComponents/ModeOfTransport.vue";
import MovementType from "../BookingTables/BookingComponents/MovementType.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import Templates from "../AgGrid/GridSideBar/Templates.vue";
import UserCell from "../GridComponents/User.vue";
export default {
  components: {
    AgGridVue,
    BookingState,
    BookingType,
    MovementType,
    ModeOfTransport,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
    UserCell,
    Templates,
  },
  data: () => ({
    booking: {},
    bookingModal: false,
    bookings: null,
    bookingTimeout: null,
    bookingQuery: null,
    tableType: "BookingsPublic",
    columns: [],
    containers: [],
    containerTimeout: null,
    containerQuery: null,
    dateKey: 200,
    dateMenu: false,
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        //   minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    gridColumnApi: null,
    loading: false,
    loadingClone: false,
    loadingOrganisations: {},
    params: {
      dateType: "ETD",
    },
    organisations: {
      data: [],
      count: 0,
    },
    quickSelectDates: [
      {
        text: "Specify Dates",
        type: "current",
        value: "select_dates",
        difference: 0,
      },
      // {
      //     text: 'Past 30 days',
      //     type: 'past',
      //     value: 'past_month',
      //     difference: -1
      // },
      {
        text: "Past 7 Days",
        value: "past_week",
        type: "past",
        difference: -7,
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
      {
        text: "Next 6 weeks",
        type: "future",
        value: "6_week",
        difference: 0,
      },
    ],
    refreshBooking: 100,
    search: null,
    searchTimeout: null,
    selectedTeams: [],
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    updatedBookingModal: false,
    updatedBookings: [],
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created() {
    if (
      localStorage[
        "publicBookingSearchParams_" + this.$store.state.currentOrg.id
      ]
    ) {
      this.params = JSON.parse(
        localStorage[
          "publicBookingSearchParams_" + this.$store.state.currentOrg.id
        ]
      );
      if (!this.params) {
        this.params = {};
      }
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
      if (
        this.params.selectedDate &&
        this.params.selectedDate != "select_dates"
      ) {
        this.selectDate(this.params.selectedDate);
      }
    } else {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        dates: [],
      };
      this.selectDate("current_week");
    }
    this.sidebar = {
      toolPanels: [
        // {
        //   id: "templates",
        //   labelDefault: "Templates",
        //   labelKey: "templates",
        //   iconKey: "colorPicker",
        //   toolPanel: "Templates",
        //   minWidth: 300,
        //   maxWidth: 500,
        //   width: 300,
        //   toolPanelParams: {
        //     tableType: this.tableType,
        //   },
        // },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
    this.columns = [
      {
        headerName: "Order No",
        field: "orderNo",
      },
      {
        headerName: "Status",
        field: "state",
        cellRenderer: "BookingState",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Movement Type",
        field: "movementType",
        cellRenderer: "MovementType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
        },
      {
        headerName: "Type",
        field: "type",
        cellRenderer: "BookingType",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Mode",
        field: "modeOfTransport",
        cellRenderer: "ModeOfTransport",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "Regime Code",
        field: "regimeCode",
      },

      {
        headerName: "Shipper",
        field: "shipper.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "Linked Shipper",
        field: "onBehalfShipper.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfShipper
            ? {
                organisation: p.data.onBehalfShipper,
              }
            : null;
        },
      },
      {
        headerName: "Forwarder",
        field: "forwarder.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.forwarder
            ? {
                organisation: p.data.forwarder,
              }
            : null;
        },
      },
      {
        headerName: "Linked Forwarder",
        field: "onBehalfForwarder.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.onBehalfForwarder
            ? {
                organisation: p.data.onBehalfForwarder,
              }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consignee.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Buyer",
        field: "buyer.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.buyer
            ? {
                organisation: p.data.buyer,
              }
            : null;
        },
      },
      {
        headerName: "Contract Party",
        field: "contractParty.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.contractParty
            ? {
                organisation: p.data.contractParty,
              }
            : null;
        },
      },
      {
        headerName: "Contract Number",
        field: "contractNumber",
      },
      {
        headerName: "Carrier",
        field: "shippingLine.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine,
              }
            : null;
        },
      },
      {
        headerName: "Vessel Operator",
        field: "vesselOperatorName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.vesselOperator
            ? {
                organisation: p.data.vesselOperator,
              }
            : null;
        },
      },
      {
        headerName: "Week",
        field: "sailingWeekText",
        cellStyle: { justifyContent: "center" },
        valueGetter: (p) => {
          return p.data && p.data.sailingWeek
            ? p.data.sailingWeekYear + " - W" + p.data.sailingWeek
            : null;
        },
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
        valueGetter: (params) => {
          if (params.data && params.data.etd) {
            let date = new Date(params.data.etd);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.etd);
            } else {
              return this.formatDateTime(params.data.etd);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "ATD",
        field: "atd",
        valueGetter: (params) => {
          if (params.data && params.data.atd) {
            let date = new Date(params.data.atd);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.atd);
            } else {
              return this.formatDateTime(params.data.atd);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Dest.",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.finalDestinationValue : null,
            name: p.data ? p.data.finalDestinationCity : null,
          };
        },
      },
      {
        headerName: "ETA",
        field: "eta",
        valueGetter: (params) => {
          if (params.data && params.data.eta) {
            let date = new Date(params.data.eta);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.eta);
            } else {
              return this.formatDateTime(params.data.eta);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "ATA",
        field: "ata",
        valueGetter: (params) => {
          if (params.data && params.data.ata) {
            let date = new Date(params.data.ata);
            if (date.getUTCHours() === 0 && date.getUTCMinutes() === 0) {
              return this.formatDate(params.data.ata);
            } else {
              return this.formatDateTime(params.data.ata);
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Containers",
        field: "numContainers",
        cellStyle: { justifyContent: "center" },
      },
      {
        headerName: "Carrier Ref. No",
        field: "carrierReferenceNumber",
      },
      {
        headerName: "Shipper Ref. No",
        field: "shipperReferenceNo",
      },
      {
        headerName: "Consignee Ref. No",
        field: "consigneeReferenceNo",
      },
      {
        headerName: "INCO Term",
        field: "incoTerm",
      },
    ];
  },
  computed: {
    currentWeek() {
      let currentWeek = moment().isoWeek();
      let currentYear = moment().isoWeekYear();
      return `${currentYear} - W${currentWeek}`
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
  },
  async mounted() {},
  methods: {
    addBooking() {
      this.booking = {
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        bookingCargoItems: [],
        bookingPaymentDetails: [],
        productServiceSales: [],
      };
      this.bookingModal = true;
      this.refreshBooking++;
    },
    addNewBooking() {
      this.booking = {
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        bookingCargoItems: [],
        bookingPaymentDetails: [],
      };
      this.updatedBookingModal = true;
      this.refreshBooking++;
    },

    columnsChanged() {
      if (this.gridApi) {
        let columnState = this.gridApi.getColumnState();
        localStorage.setItem(
          `columnState-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`,
          JSON.stringify(columnState)
        );
        localStorage.setItem(
          `pivotMode-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`,
          JSON.stringify({ pivotMode: this.gridApi.isPivotMode() })
        );
      }
    },
    changeDateType() {
      if (this.params.dateType == "ETD") {
        this.params.dateType = "ETA";
      } else {
        this.params.dateType = "ETD";
      }
      localStorage.setItem(
        "publicBookingSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.dateKey++;
      this.getBookings();
    },

    async getBookings() {
      if (this.bookingTimeout) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loading && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loading = true;
      let params = { ...this.params };
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        this.bookings = await this.$API.getPublicBookings({
          params: params,
          signal,
        });
        this.loading = false;
      }, 500);
    },
    getMainMenuItems(params) {
      let result = [
        "sortAscending",
        "sortDescending",
        "sortUnSort",
        "separator",
        "pinSubMenu",
        "separator",
        "autoSizeThis",
        "autoSizeAll",
        "separator",
        "rowGroup",
        "rowUnGroup",
      ];

      return result;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
      localStorage.setItem(
        `filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`,
        JSON.stringify(this.gridApi.getFilterModel())
      );
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "yyyy-mm-dd");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getRelations() {
      this.loadingOrganisations = true;
      this.organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      this.loadingOrganisations = false;
    },
    getRowId(params) {
      return params.data.id;
    },
    async load() {
      this.getBookings();
      await this.getRelations();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.load();
    },
    async resetFilters() {
      this.gridApi.setFilterModel(null);
      localStorage.setItem(
        `filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`,
        JSON.stringify(this.gridApi.getFilterModel())
      );
    },
    resetTable() {
      this.gridApi.setFilterModel(null);
      this.gridApi.setRowGroupColumns([]);
      this.gridApi.applyColumnState({ state: this.columns, applyOrder: true });
      this.gridApi.refreshCells({ force: true });
    },

    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getBookings();
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem(
        "publicBookingSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem(
        "publicBookingSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.dateMenu = false;
      this.getBookings();
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
    viewBookingNew(item) {
      this.$router.push({
        path: "/booking/manage/" + item.systemReference,
      });
    },
  },
};
</script>
  <style>
.searchChipWrap {
  width: 70%;
  display: grid;
  transition: width 0.3s ease-in-out;
}
.innerContent {
  height: 100%;
  width: 100%;
}
.cell-flash-permanent {
  background-color: #2a5b84 !important;
}

.ag-grid-theme-builder {
  --ag-icon-font-display-color-picker: none;
  --ag-icon-font-color-color-picker: transparent;
}

.ag-grid-theme-builder ::v-deep .ag-icon.ag-icon-color-picker {
  scale: 1.2;
}
</style>