<template>
  <div>
    <v-card
      flat
      style="
        background-color: var(--v-background-base) !important;
        height: calc(100vh - 25px);
      "
    >
      <v-toolbar
        :class="
          !$vuetify.breakpoint.mobile ? 'toolbarHead' : 'toolbarHeadMobile'
        "
        color="greyBase"
        dark
        flat
        dense
        height="30px"
      >
        <v-toolbar-title
          v-if="!$vuetify.breakpoint.mobile"
          style="font-size: 16px"
        >
          <b class="mr-3">Shipments</b>
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs height="30px" v-model="currentTab" class="tabby">
        <v-tab
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :href="`#${tab.value}`"
          style="font-size: 12px"
        >
          <v-icon small color="grey" class="mr-2">{{ tab.icon }}</v-icon>
          <span>{{ tab.name }}</span>
        </v-tab>
      </v-tabs>
      <div class="contents">
        <div class="mainContentWrap">
          <div class="mainContent">
            <v-tabs-items
              v-model="currentTab"
              style="height: 100%; width: 100%"
            >
              <v-tab-item value="bookings">
                <Bookings />
              </v-tab-item>
              <v-tab-item value="containers">
                <Containers />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import Bookings from "@/components/PublicShipments/Bookings.vue";
import Containers from "@/components/PublicShipments/Containers.vue";
export default {
  components: {
    Bookings,
    Containers,
  },
  data: () => ({
    currentTab: "containers",
    tabs: [
      {
        value: "containers",
        name: "Containers",
        icon: "widgets",
      },
      {
        value: "bookings",
        name: "Bookings",
        icon: "confirmation_number",
      },
    ],
  }),
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  height: calc(100vh - 66px);
  width: 100%;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight) - 55px);
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
  height: var(--tabHeight) !important;
}
</style>